import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import gemeinden from '../data/gemeinden.json'

import {
    Landing, Introduction, Download, Powerspeed, Bluecode, BonusVerein,
    Videoplayer, Numbers, Reviews, SocialMedia, Carousel
} from '../components'

import localData from '../data/data.json'

const Home = () => {
    let { param } = useParams()
    const [city, setCity] = useState("")
    const [showPowerspeed, setShowPowerspeed] = useState(false)

    const [dataLoaded, setDataLoaded] = useState(false);
    const [data, setData] = useState({});
    useEffect(() => {
        getJson();
        returnGnr(param);
    }, [param])

    function getJson() {
        fetch(process.env.PUBLIC_URL + '/fetchGem2GoData.json', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setData(data);
                setDataLoaded(true);
            })
            .catch((error) => {
                console.error(error);
                setData(localData);
                setDataLoaded(true);
            });
    }
    const returnGnr = (param) => {
        if (param) {
            let url = param.toLowerCase();
            if (url.endsWith("-niederoesterreich")) {
                url = url.replace("-niederoesterreich", "");
            }
            if (url.endsWith("hotspot")) {
                setShowPowerspeed(true);
                url = url.replace("hotspot", "");
            }
            const match = gemeinden.filter(gemeinde => gemeinde.URL === url);
            if (match.length !== 0) {
                setCity(match[0].Ortname);
            }
        }
    }


    return (
        <div style={{ backgroundColor: "#fff" }}>
            { dataLoaded && (
                <>
                    <Landing text={data.introductionText} gemeinde={city} partners={data.partners} />
                    <Introduction text={data.appText} />
                    <Download data={data.downloadText} />
                    <Carousel features={data.features} />
                    <Numbers data={data.numbers} />
                    <BonusVerein />
                    {showPowerspeed && <Powerspeed />}
                    <Videoplayer />
                    <Reviews data={data.reviews} />
                    <SocialMedia data={data.socialMedia} />
                </>
            )}
        </div>
    )
}

export default Home