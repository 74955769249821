import styled from "styled-components";

export const Wrapper = styled.div`
	background: linear-gradient(
		90deg,
		rgba(239, 51, 68),
		rgba(203, 0, 55, 0.8)
	);
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	/* min-height: 320px; */
	padding: 2em 0;
	& img {
		display: none;
	}
	@media screen and (min-width: 1075px) {
		& img {
			display: unset;
			height: 400px;
			position: relative;
			bottom: -2rem;
		}
	}
`;

export const Content = styled.div`
	width: 100vw;
	max-width: 1600px;
	padding: 0 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	color: white;
	box-sizing: border-box;
`;

export const Container = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	/* flex-basis: 65%; */
	& * {
		color: white;
	}
	& h2 {
		font-size: 3rem;
		font-weight: 800;
		margin-bottom: 1.5rem;
		@media (max-width: 478px) {
			font-size: 2rem;
		}
	}
	& > span {
		margin-bottom: 1rem;
	}
	& a {
		text-decoration: none;
		border: 1px solid white;
		border-radius: 10px;
		padding: 0.5rem 1rem;
		margin: 1rem 1rem 0 0;
		transition: 0.3s ease;
		span {
			margin-left: 0.5rem;
		}
		:hover {
			background-color: white;
			* {
				color: var(--red);
			}
			svg * {
				fill: var(--red);
			}
		}
		@media screen and (max-width: 680px) {
			margin: .5rem auto;
			width: 50%;
		    justify-content: center;
		}
	}
`;

export const Links = styled.div`
	display: flex;
	flex-wrap: wrap;
	a {
		display: flex;
		align-items: center;
	}
	svg {
		width: 24px;
		height: 24px;
		/* background-color: red; */
	}
	svg * {
		width: 100%;
		height: 100%;
		fill: white;
	}
`;
