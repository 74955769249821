import styled from "styled-components";

export const NavWrapper = styled.div`
	position: fixed;
	z-index: 999;
	width: 100vw;
	display: flex;
	justify-content: center;
	${(props) => {
		if (props.toggle) {
			return `
        background-color: rgba(0, 0, 0, 0);
        & a {
          color: white;
        }
        @media (min-width: 1000px) {
			& a:after {
			display: block;
			content: "";
			height: 2px;
			background-color: white;
			width: 0;
			transition: all 0.1s ease-in 0s;
			margin: 0 auto;
		}
		& .button:hover {
			background: white;
			color: var(--red);
			text-decoration: none;
		}
          & .button {
            border: 2px solid white;
          }
        }
        & img {
          filter: brightness(0) invert(1);
        }
        & nav div > div{
          background-color: white;
        }
      `;
		} else {
			return `
        box-shadow: 0 4px 2px -2px rgba(0,0,0,.5);    
        background-color: #fff;
        & a {
          color: var(--red);
        }
        @media (min-width: 1000px) {
			& a:after {
			display: block;
			content: "";
			height: 2px;
			background-color: var(--red);
			width: 0;
			transition: all 0.1s ease-in 0s;
			margin: 0 auto;
		}
		& .button:hover {
			background: var(--red);
			color: white;
			text-decoration: none;
		}
          & .button {
            border: 2px solid var(--red);
          }
        }
        & nav div > div{
          background-color: var(--red);
        }
      `;
		}
	}}
`;

export const Nav = styled.nav`
	height: 5rem;
	padding: 0 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100vw;
	max-width: 1600px;
	transition: 0.2s ease-in;
	box-sizing: border-box;
	& img {
		height: 50px;
	}
	& a {
		transition: 0.2s ease-in;
		padding: 0.5rem 1.15rem;
		text-decoration: none;
		font-weight: normal;
	}

	& button {
		background-color: white;
		border: 2px solid var(--red);
		color: var(--red);
		position: fixed;
		bottom: 1rem;
		right: 1rem;
		height: 4rem;
		width: 4rem;
		border-radius: 50%;
	}
	@media screen and (min-width: 1000px) {
		& .button {
			padding: 0.5rem 1rem;
			border-radius: 10px;
		}
	}
`;

export const Wrapper = styled.div`
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	@media screen and (min-width: 1000px) {
		& a:hover:after {
			width: 100%;
		}
		& .button:after {
			height: 0;
		}
		& .button:hover:after {
			width: 0;
		}
	}

	@media (max-width: 999px) {
		box-sizing: border-box;
		flex-flow: column nowrap;
		align-items: flex-start;
		background-color: #061623;
		position: fixed;
		overflow-y: scroll;
		transform: ${({ open }) =>
			open ? "translateX(0)" : "translateX(100%)"};
		top: 0;
		right: 0;
		height: 100vh;
		width: 300px;
		padding: 3.5rem 0 0 2rem;
		margin: 0;
		transition: transform 0.3s ease-in-out;
		& * {
			color: white !important;
			width: 100%;
		}
		& a {
			padding: 1.5rem 0;
		}
	}
`;

export const StyledBurger = styled.div`
	width: 2rem;
	height: 2rem;
	position: fixed;
	top: 25px;
	right: 20px;
	z-index: 20;
	display: none;
	@media (max-width: 999px) {
		display: flex;
		justify-content: space-around;
		flex-flow: column nowrap;
	}
	div {
		width: 2rem;
		height: 0.25rem;
		border-radius: 10px;
		transform-origin: 1px;
		transition: all 0.3s linear;
		&:nth-child(1) {
			transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
		}
		&:nth-child(2) {
			transform: ${({ open }) =>
				open ? "translateX(100%)" : "translateX(0)"};
			opacity: ${({ open }) => (open ? 0 : 1)};
		}
		&:nth-child(3) {
			transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
		}
	}
`;

export const ScrollButton = styled.button`
	background-color: white;
	border: 2px solid var(--red);
	color: var(--red);
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	height: 4rem;
	width: 4rem;
	border-radius: 50%;

	&:focus {
		outline: 1px solid black;
	}
`;
