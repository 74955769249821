import React from 'react'
import ReactPlayer from 'react-player/lazy'
import LazyLoad from 'react-lazyload'

import { Wrapper, VideoWrapper, Container } from './Styles'
import Heading from '../heading'

const Videoplayer = () => {

    return (
        <Wrapper>
            <Heading id="video" top="Video" header="Kurzvorstellung: GEM2GO" />
            {/* <LazyLoad style={{width: "100%"}}> */}
            <div style={{maxWidth: "1200px", width: "100%"}}>

                <Container>
                    <VideoWrapper imgUrl={process.env.PUBLIC_URL + '/assets/yt-img.webp'}>
                        <ReactPlayer
                            width='100%'
                            height='100%'
                            style={{position: "absolute", top: "0"}}
                            url='https://youtu.be/f1Kaf_A5op0'
                            light='true'
                        />  
                    </VideoWrapper>
                </Container>
                            </div>
            {/* </LazyLoad> */}
        </Wrapper>
    )
}

export default Videoplayer