import React, { useState, useEffect } from 'react'

import { Wrapper, ButtonContainer, DeclineButton, AcceptButton } from './Styles'

const CookiePopup = ({setPopupShown}) => {
    const [cookieConsent, setCookieConsent] = useState(false);

    useEffect(() => {
        if(cookieConsent){
            localStorage.setItem('PopupShown', 'True');
            setPopupShown(true);
        }
    }, [cookieConsent])

    return (
        <Wrapper>
            <span>Wir nutzen Cookies auf unserer Website. Sie helfen uns, diese Website und Ihre Erfahrung zu verbessern.</span>
            <ButtonContainer>
                <DeclineButton onClick={() => setPopupShown(true)}>Blockieren</DeclineButton>
                <AcceptButton onClick={() => setCookieConsent(true)}>Verstanden</AcceptButton>
            </ButtonContainer>
        </Wrapper>
    )
}

export default CookiePopup
