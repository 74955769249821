import React from 'react'
import { Landing, Content} from './Styles'
import styled  from 'styled-components'

import gemeinden from '../data/gemeinden.json'

const Sitemap = () => {
    const sortArray = (a,b) => {
        if(a.Ortname < b.Ortname) { return -1; }
        if(a.Ortname > b.Ortname) { return 1; }
        return 0;
    }

    return (
        <div style={{ minHeight: "150vh", textAlign: "center", backgroundColor: "#fff" }}>
            <Landing>
                <h1>Sitemap</h1>
            </Landing>
            <Content>
                <h2>Übersicht aller Gemeinden</h2>
                <Gemeinden>
                    {gemeinden.sort(sortArray).map(gemeinde => (
                        <Gemeinde href={`/${gemeinde.URL}`}>
                            {gemeinde.Ortname}
                        </Gemeinde>
                    ))}
                </Gemeinden>
            </Content>
        </div>
    )
}

const Gemeinden = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const Gemeinde = styled.a`
    display: flex;
    width: 250px;
    height: 100px;
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    text-decoration: none;
    transition: 0.2s ease-in;
    font-weight: 800;
    &:hover{
        color: white;
        background-color: var(--red);
    }
`;

export default Sitemap