import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faNewspaper, faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import LazyLoad from 'react-lazyload'
import DOMPurify from 'dompurify'

import { Wrapper, Icons, Icon } from './Styles'
import Heading from '../heading'

library.add(faFacebookF, faInstagram, faYoutube, faNewspaper, faArrowToBottom);

const SocialMedia = ({ data }) => {
    function createIcon(icon) {
        if (icon.startsWith("fab")) {
            let arr = icon.split("fab-")
            return <FontAwesomeIcon icon={["fab", arr[1]]} color="white" alt="" role="presentation" />
        }
        else if (icon.startsWith("far")) {
            let arr = icon.split("far-")
            return <FontAwesomeIcon icon={["far", arr[1]]} color="white" alt="" role="presentation" />
        } else {
            return <div className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(icon) }}></div>
        }
    }
    function checkAnchor(href){
        if(href.indexOf("#") == 0)
            return "";
        else
            return "_blank";
    }

    return (
        <Wrapper id="contact">
            <Heading top="Soziale Netzwerke" header="Bleib auf dem aktuellsten Stand" />
            <LazyLoad offset={300}>
                <Icons>
                    {data.map((item, index) => (
                        <Icon key={index} href={item.link} rel="noopener" target={checkAnchor(item.link)}>
                            <div>
                                {createIcon(item.icon)}
                            </div>
                            <span>{item.name}</span>
                        </Icon>
                    ))}
                </Icons>
            </LazyLoad>
        </Wrapper>
    )
}

export default SocialMedia