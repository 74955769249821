import React from "react";
import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.css";

import Wrapper from "./pages/Wrapper";
import Home from "./pages/Home";
import Imprint from "./pages/Imprint";
import Privacy from "./pages/Privacy";
import Sitemap from "./pages/Sitemap";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path=':param' element={<Wrapper />}>
					<Route path='/:param' element={<Home />} />
					<Route path='impressum' element={<Imprint />} />
					<Route path='datenschutz' element={<Privacy />} />
					<Route path='sitemap' element={<Sitemap />} />
					
				</Route>
				<Route path='' element={<Wrapper />}>
					<Route path='/' element={<Home />} />
					<Route path='impressum' element={<Imprint />} />
					<Route path='datenschutz' element={<Privacy />} />
					<Route path='sitemap' element={<Sitemap />} />
					<Route path='/' render={() => <div>404</div>} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
