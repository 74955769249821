import styled from "styled-components";

export const Wrapper = styled.div`
	padding-top: 0rem;
	text-align: center;
	& > span {
		position: relative;
		text-transform: uppercase;
		font-weight: 600;
		color: var(--text);
		margin-bottom: 0.5em;
		display: block;
		> span {
			font-size: 1.5rem;
			position: absolute;
			top: -100px;
		}
	}
`;

export const Header = styled.h2`
	margin-top: 0;
	font-weight: 800;
	font-size: 3rem;
	@media (max-width: 478px) {
		font-size: 2rem;
	}
	${(props) => {
		if (props.white) {
			return `
				color: white;
				font-weight: 800;
			`;
		}
		if (props.blue) {
			return `
				color: var(--blue);
			`;
		}
		if (props.bluecode) {
			return `
				color: var(--bluecode);
			`;
		}
	}}
`;
