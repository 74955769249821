import styled from "styled-components";

export const Wrapper = styled.div`
	padding: 6rem 0;
	background-color: #ededed;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
`;

export const Icons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 1rem;
`;
export const Icon = styled.a`
	text-decoration: unset;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: 0.2s ease;
	margin: 1rem 0;
	& div {
		height: 60px;
		width: 60px;
		border-radius: 10px;
		border: 4px solid var(--red);
		margin: 0.5rem 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.2s ease;
		& svg {
			color: var(--red);
			font-size: 2rem;
		}
	}
	& span {
		text-align: center;
		color: var(--red);
		font-weight: 800;
		text-transform: uppercase;
	}
	&:hover {
		cursor: pointer;
		transform: scale(1.1);
		& div {
			box-shadow: 0 0 14px var(--red);
			background-color: var(--red);
			& svg {
				color: #fff;
			}
		}
	}
`;
