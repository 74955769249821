import React from "react";

import Heading from "../heading";
import { Wrapper, Button, Container, Links } from './Styles'

const Powerspeed = () => {
	return (
		<Wrapper>
			<Container>
				<Heading
					id='powerspeed'
					top='Das Glasfasernetz der Energie AG'
					header='Powerspeed - Das schnellste Internet im Land'
					isBlue
				/>
				<div>
					<div>
						<p>
							Das lichtschnelle Glasfasernetz der Energie AG
							Telekom verbindet bereits jetzt alle
							oberösterreichischen Gemeinden mittels
							Glasfaserleitungen - und bildet somit die Grundlage
							für einen flächendeckenden "Fiber to the
							home"-Zugang in ganz Oberösterreich. In ausgewählten
							Regionen Oberösterreichs können Privatkunden bereits
							jetzt auf der Datenautobahn der Zukunft mittels
							powerSPEED Glasfaserinternet surfen.
						</p>
						<p>
							Durch einen direkten Anschluss der Haushalte an das
							weltweite Glasfasernetz können höchst mögliche
							Geschwindigkeiten erreicht werden. Und schon bald
							werden wir uns die Frage stellen: Gab es je eine
							Welt ohne diesem Glasfasernetz?
						</p>
						<p>
							Bei <strong>FTTH</strong> wird ein Glasfaserkabel bis in Ihr
							Haus und zum Endgerät verwendet. Mit einem
							FTTH-Anschluss gehört langsames Internet der
							Vergangenheit an, da hier keine veralteten
							Technologien wie 2-Draht oder Koaxialkabel zum
							Einsatz kommen, die die Verbindung bremsen würden.
							Um <strong>nur 19,90€*</strong> bekommen Sie bereits einen
							Internet-Zugang mit <strong>lichtschnellen 100Mbit/s</strong>.
						</p>
					</div>
				</div>
			</Container>
			<Links>
				<div className='logo_powerSpeed'>
					<a
						target='_blank'
						href='https://www.power-speed.at/'
						rel="noopener noreferrer"
					>
						<img
							src={
								process.env.PUBLIC_URL + "assets/powerspeed.webp"
							}
							alt='Logo Powerspeed'
							height="50"
						/>
					</a>
				</div>
				<iframe
					className='powerSpeed'
					src='https://www.youtube.com/embed/zpPengT7SY8?rel=0&amp;showinfo=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fwww.gem2go.at'
					data-gtm-yt-inspected-6280375_41='true'
					id='628516638'
					title="powerSpeed"
					style={{border: 0}}
				></iframe>
				<Button>
					<a
						href='https://privat.power-speed.at/'
						className='powerSpeedButton extlink'
						target='_blank'
						rel="noopener noreferrer"
					>
						Verfügbarkeit prüfen
					</a>
				</Button>
			</Links>
		</Wrapper>
	);
};

export default Powerspeed;
