import styled from "styled-components";

export const Wrapper = styled.div`
    margin: auto;
    max-width: 1600px;
    padding: 6rem 0;
    box-sizing: border-box;
`

export const Inner = styled.div`
    height: 400px;
    margin: 0 auto;
    & img{
        user-drag: none;
        height: 100%;
        margin: 0 auto;
    }
    @media screen and (min-width: 650px) {
        height: 550px;
    } 
`