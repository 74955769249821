import React from 'react'
import { Landing, Content } from './Styles'

const Imprint = () => {
    return (
        <div style={{ minHeight: "150vh", backgroundColor: "#fff" }}>
            <Landing>
                <h1>Impressum</h1>
            </Landing>
            <Content>
                <h2>1.Impressum/Offenlegung gem&auml;&szlig; Mediengesetz</h2>
                <p>Medieninhaber: RIS GmbH</p>
                <p>Herausgeber:</p>
                <p>RIS GmbH<br />Im Stadtgut Zone A 1<br />4407 Steyr-Gleink<br />Tel.: +43 (7252) 86186 - 0<br />Fax: +43 (7252) 86186 - 33<br />Web:&nbsp;<a title="www.ris.at" href="https://www.ris.at/" target="_self">www.ris.at</a><br />E-Mail:&nbsp;<a title="RiS GmbH Office" href="mailto:office@ris.at">office@ris.at</a><br />DVR-NR.<br />Firmenbuch-Nr. 142028s<br />Landesgericht Steyr<br />UID ATU 40401409<br />Mitglied der Wirtschaftskammer &Ouml;sterreich, Fachgrupppe Unternehmensberatung und Informationstechnologie</p>
                <p>Unternehmensgegenstand:&nbsp;<a title="RiS GmbH WKO" href="https://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=4d320f53-55a9-4d4d-b9c5-98d574757a80" target="_blank" rel="noopener noreferrer">Information finden Sie hier ...</a></p>
                <p><a title="RiS GmbH WKO" href="https://www.wkoecg.at/Web/Ecg.aspx?FirmaID=d8889499-fb5f-4fa3-b6a4-e013ae74b7bb" target="_blank" rel="noopener noreferrer">Information laut E-Commerce-Gesetz</a></p>
                <p><a title="GF Ing. Mag.(FH) Michael K&ouml;lbl" href="https://www.ris.at/Unternehmen/person.aspx?typ=211&amp;bezirkonr=0&amp;detailonr=50433422&amp;menuonr=50432263">Gesch&auml;ftsf&uuml;hrung</a></p>
                <p><a title="Gesellschafter RiS GmbH" href="http://www.ris.at/Unternehmen/Eigentuemer" target="_self">Gesellschafter</a></p>
                <p><a title="Kontakt RiS GmbH" href="https://www.ris.at/system/web/kontakt.aspx?menuonr=50432265&amp;bezirkonr=0" target="_self">Anreise</a></p>
                <h2>2. Allgemeine Nutzungsbedingungen</h2>
                <p>Der Nutzer anerkennt, dass die Nutzung der von der RIS GmbH angebotenen Dienste im Internet ausschlie&szlig;lich unter Anwendung dieser allgemeinen Nutzungsbedingungen erfolgt.</p>
                <p>Der Nutzer nimmt zur Kenntnis, dass die RIS GmbH keine Verpflichtung &uuml;bernimmt, die von ihr im Internet angebotenen Dienste unterbrechungsfrei und jederzeit abrufbar zu halten.</p>
                <p>Sowohl die Zurverf&uuml;gungstellung als auch die Nutzung der Dienste und Inhalte unterliegen den einschl&auml;gigen gesetzlichen Regelungen, insbesondere des Datenschutzes, des Urheberrechts sowie des Marken- und Musterschutzes.</p>
                <p>Die auf den Internetseiten der RIS GmbH wiedergegebenen Inhalte dienen, trotz eingehender Recherche und Aufarbeitung, lediglich zur Information. F&uuml;r dennoch enthaltene Fehler kann keine wie immer geartete Haftung &uuml;bernommen werden. Weiters erkl&auml;rt sich die RIS GmbH als nicht verantwortlich f&uuml;r den Inhalt von Internetseiten, auf die mit einem Link verwiesen wird.</p>
                <h2>3. Allgemeine Hinweise</h2>
                <p>Bei der Zusammenstellung von Texten und Abbildungen wurde mit gr&ouml;&szlig;ter Sorgfalt vorgegangen. Trotzdem k&ouml;nnen Fehler nicht vollst&auml;ndig ausgeschlossen werden. Der Herausgeber kann f&uuml;r fehlerhafte Angaben und deren Folgen weder eine juristische Verantwortung noch irgendeine Haftung &uuml;ber-nehmen. F&uuml;r Verbesserungsvorschl&auml;ge und Hinweise auf eventuelle Fehler ist der Herausgeber dankbar.</p>
                <p>Alle Rechte vorbehalten. Kein Teil dieses Online-Auftrittes darf in irgendeiner Form (Druck, Fotokopie oder einem anderen Verfahren) ohne schriftliche Genehmigung der RIS GmbH reproduziert oder unter Verwendung elektronischer Systeme verarbeitet, vervielf&auml;ltigt oder verbreitet werden.</p>
                <p>Hinweis zur geschlechtsneutralen Formulierung:&nbsp;Wir wenden uns mit unseren Aussagen an Sie als unsere Kundinnen und Kunden / gesch&auml;tzte Gesch&auml;ftspartnerinnen und Gesch&auml;ftspartner. In vollem Respekt und Wertsch&auml;tzung f&uuml;r beiderlei Geschlechter gelten unsere getroffenen Formulierungen f&uuml;r das weibliche und m&auml;nnliche Geschlecht gleicherma&szlig;en.</p>
                <p>4. Verweis auf den Firmeneintrag im Verzeichnis der Wirtschaftskammer &Ouml;sterreich</p>
                <p><a title="RiS GmbH WKO" href="https://www.wkoecg.at/Web/Ecg.aspx?FirmaID=d8889499-fb5f-4fa3-b6a4-e013ae74b7bb" target="_blank" rel="noopener noreferrer">Informationen finden Sie hier ...</a></p>
                <h3>Statistische Auswertungen</h3>
                <p>Diese Website benutzt eine Open-Source-Software zur statistischen Auswertung der Besucherzugriffe. Diese verwendet sog. &ldquo;Cookies&rdquo;, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie erm&ouml;glichen. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieses Internetangebotes werden auf dem Server des Anbieters in &Ouml;sterreich gespeichert. Die IP-Adresse wird sofort nach der Verarbeitung und vor deren Speicherung anonymisiert. Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich nutzen k&ouml;nnen.</p>
                <p>&nbsp;</p>
                <p><a href="https://www.ris.at/system/web/datenschutz.aspx?menuonr=50432282">Information zum Datenschutz</a></p>
            </Content>
        </div>
    )
}

export default Imprint
