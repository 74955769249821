import React from 'react'
import { Link } from 'react-router-dom'

import { Wrapper, Content, Copyright, Links } from './Styles'

const Footer = () => {
    const scrollHome = () => {
        window.scrollTo({ top: 0 });
    };

    return (
        <Wrapper>
            <Content>
                <Copyright>
                    ©
                    <strong> {new Date().getFullYear()} </strong>
                        Alle Rechte vorbehalten - GEM2GO ist ein Produkt der
                    <a className="extlink" href="https://www.ris.at" rel="noopener noreferrer" target="_blank">RIS GmbH</a>
                </Copyright>
                <Links>
                    {/* <Link to="/funktionen">Alle Funktionen</Link>
                    - */}
                    <Link onClick={scrollHome} to="impressum">Impressum</Link>
                    -
                    <Link onClick={scrollHome} to="datenschutz">Datenschutz</Link>
                    -
                    <Link onClick={scrollHome} to="sitemap">Sitemap</Link>
                </Links>
            </Content>
        </Wrapper>
    )
}

export default Footer