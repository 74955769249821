import React from 'react';
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

import { Wrapper } from './Styles'

const RightNav = ({ open, setOpen, showPowerspeed }) => {
    const scrollHome = (open) =>{
        setOpen(!open);
        window.scrollTo({top: 0, behavior: 'smooth'});
      };

    return (
        <Wrapper open={open}>
            <Link onClick={() => scrollHome(open)} to=''>Home</Link>
            <HashLink onClick={() => setOpen(!open)} smooth to="#app">GEM2GO</HashLink>
            <HashLink onClick={() => setOpen(!open)} smooth to="#features">Funktionen</HashLink>
            { showPowerspeed && <HashLink onClick={() => setOpen(!open)} smooth to="#powerspeed">Powerspeed</HashLink>}
            <HashLink onClick={() => setOpen(!open)} smooth to="#numbers">Zahlen & Fakten</HashLink>
            <HashLink onClick={() => setOpen(!open)} smooth to="#video">Video</HashLink>
            <HashLink onClick={() => setOpen(!open)} smooth to="#contact">Kontakt</HashLink>
            <HashLink onClick={() => setOpen(!open)} smooth to="#download" className="button">Download</HashLink>
        </Wrapper>
    )
}

export default RightNav