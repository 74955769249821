import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 10rem 0;
    background:linear-gradient(90deg, rgba(203, 0, 55, .8), rgba(203, 0, 55, .8)), url(${(props) => props.imgUrl});
    background-size:cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    & h2, & span{
        color: white;
    }
    `

export const NumberWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & > div{
        flex-basis: 100%;
        padding: .5rem 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 800;
        span:nth-child(2){
            font-size: 3rem;
        }
        @media (min-width: 769px) {
            flex: 0 0 auto;
        }
    }
`
    