import styled from "styled-components";

export const Background = styled.div`
	/*background-color: rgb(204, 8, 57);*/
	margin-top: 4em;
	box-sizing: border-box;
	/*@media screen and (max-width: 1150px) {
		background: linear-gradient(
			180deg,
			rgb(204, 8, 57) 20%,
			rgb(77, 26, 148) 65%
		);
	}*/

`;

export const Wrapper = styled.div`
	box-sizing: border-box;
	max-width: 1600px;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	gap: 5rem;
	padding: 5rem 2rem;
	padding-top: 2rem;
	color: #fff;
	@media screen and (max-width: 1150px) {
		flex-wrap: wrap;
	}

`;

export const Block = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2rem;
    div {
        h2 {
            color: var(--red);
        }
        p {
			color: #000;
            padding-bottom: 2rem;
        }
    }
	img {
		max-width: 200px;
		height: auto;
	}
	@media screen and (max-width: 680px) {
		flex-direction: column;

		img {
			display: none;
		}
	}
`;

export const Link = styled.a`
	color: #fff;
	text-decoration: none;
	border: 2px solid var(--red);
	border-radius: 10px;
	padding: 0.5rem 1rem;
	margin: 1rem 1rem 0 0;
	transition: 0.3s ease;
	background-color: var(--red);
	:hover {
		background-color: #fff;
		color: var(--red);
	}
`;
