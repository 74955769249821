import styled from "styled-components";

export const Container = styled.div`
	width: 70%;
	margin: 0 auto;
	
	`

export const Wrapper = styled.div`
	background-color: #ededed;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	padding: 4rem 0;
	`;

export const VideoWrapper = styled.div`
	width: 100%;
	position: relative;
	padding-bottom: 56.25%;
	background-position: center;
    background-size: cover;
	background-image: url(${(props) => props.imgUrl});
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid black;
	border-radius: 20px;
	overflow: hidden;
	box-sizing: border-box;

	& .react-player__shadow {
		background: #cb0037 !important;
		opacity: 0.7;
		background: rgb(203, 0, 55) !important;
		width: 4em !important;
		height: 4em !important;
		font-size: 3em;
		border-radius: 4em !important;
		box-shadow: rgb(203, 0, 55) 0em 0em 1em;
		transition: opacity 150ms linear;
	}

	& .react-player__play-icon {
		border-width: 1em 0 1em 1.5em !important; 
		margin-left: .55em !important;
	}

	& :hover .react-player__shadow {
		opacity: 1;
	}
	@media (max-width: 678px) {
		/* max-width: 90vw;
		height: 220px; */
		& .react-player__shadow {
			font-size: 1em;
		}
	}
`;
