import React from 'react'

import { Background, Wrapper, Block, Link } from './Styles'
import Heading from '../heading'

function BonusVerein() {
    return (
        <Background>
            <Heading top="Mein Netzwerk" header="Eine Plattform für alle Mitglieder der Gemeinde" />
            <Wrapper>
                <Block>
                    <img src={process.env.PUBLIC_URL + 'assets/verein_optimierteAPP.png'} alt="Screenshot Mein Verein in der GEM2GO App" />
                    <div>
                        <h2>
                            Mein Verein
                        </h2>
                        <p>
                            Gib den Vereinen in deiner Gemeinde eine Bühne. Vereine haben die Möglichkeit auch in der GEM2GO APP vertreten zu sein, News zu erstellen, Veranstaltungen anzulegen und Push-Nachrichten zu verschicken.
                        </p>
                        <Link href="https://www.gem2go.info/Mein_Verein" rel="noopener" target="_blank">
                            zu Mein Verein
                        </Link>
                    </div>
                </Block>
                <Block>
                    <img src={process.env.PUBLIC_URL + 'assets/betrieb_optimierteAPP.png'} alt="Screenshot Mein Betrieb in der GEM2GO App" />
                    <div>
                        <h2>
                            Mein Betrieb
                        </h2>
                        <p>
                            Stärke jetzt gemeinsam mit uns die Wirtschaft in deiner Gemeinde oder Region. Hol dir für dein Unternehmen die GEM2GO "Mein Betrieb" und liefere den Bürgerinnen und Bürger tolle Vorteile und Infos direkt aufs Smartphone.
                        </p>
                        <Link bonus href="https://www.gem2go.info/Mein_Betrieb" rel="noopener" target="_blank">
                            zu Mein Betrieb
                        </Link>
                    </div>
                </Block>
            </Wrapper>
        </Background>
    )
}

export default BonusVerein
