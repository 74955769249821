import styled from "styled-components";

export const Background = styled.div`
	background-color: #fff;
`;

export const Wrapper = styled.div`
	max-width: 1600px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	padding: 4rem 0;
	& img {
		max-width: 80vw;
		object-fit: contain;
	}
`;

export const Container = styled.div`
	margin: 0 auto;
	padding: 2rem;
	max-width: 1000px;
`;

export const Links = styled.div`
	margin: 0 auto;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Button = styled.button`
	background: var(--blue);
	margin: 0.5rem;
	padding: 0.5rem 1rem;
	border-radius: 10px;
	transition: 0.3s ease;
	& a {
		color: white;
		text-decoration: none;
	}
	&:hover {
		color: var(--red);
		cursor: pointer;
	}
`;
