import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from 'react-device-detect'
import { HashLink } from 'react-router-hash-link';
import DOMPurify from 'dompurify';

import { Wrapper, Content, Container, Links, TextContainer, Partner, ImgWrapper } from './Styles'

const index = ({ text, gemeinde, partners }) => {
    return (
        <Wrapper imgUrl={process.env.PUBLIC_URL + '/assets/header-img.png'}>
            <Content>
                <Container>
                    <TextContainer>
                        <h1>Dein{gemeinde ? ` ${gemeinde}` : "e Gemeinde"}<br /><span>Deine GEM2GO APP</span></h1>
                        <div className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}></div>
                        <Links>
                            <HashLink smooth to="#download">
                                <FontAwesomeIcon icon={faArrowDown} size="lg" alt="" role="presentation" />
                                <span>Jetzt herunterladen</span>
                            </HashLink>
                            <HashLink smooth to="#video">
                                <FontAwesomeIcon icon={faPlayCircle} size="lg" alt="" role="presentation" />
                                <span>Video ansehen</span>
                            </HashLink>
                        </Links>
                    </TextContainer>
                    <Partner>
                        <span>Offizielle Partner</span>
                        <ImgWrapper>
                            {
                                partners.sort(() => (0.5 - Math.random())).slice(0, 7).map(p => (
                                    <img height="35" src={process.env.PUBLIC_URL + 'assets/partners/' + p.image} loading="lazy" alt={p.alt} />
                                ))
                            }
                        </ImgWrapper>
                    </Partner>
                </Container>
                <img height="625" width="320" src={!isMobile ? (process.env.PUBLIC_URL + '/assets/iphone2.webp') : undefined} alt="Schwebendes iPhone welches das GEM2GO Logo anzeigt" />
            </Content>
        </Wrapper>
    )
}

export default index