import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ReactPixel from 'react-facebook-pixel';

import { Navbar, Footer, CookiePopup } from '../components'
import { Outlet } from "react-router-dom";


const Wrapper = () => {
    let { param } = useParams()
    const [showPowerspeed, setShowPowerspeed] = useState(false)
    useEffect(() => {
        returnGnr(param);
    }, [param])

    const returnGnr = (param) => {
        if (param){
            let url = param.toLowerCase();
            if (url.endsWith("hotspot")){
                setShowPowerspeed(true);
            }
        }
    }

    const [popupShown, setPopupShown] = useState(false);

    useEffect(() => {
        ReactPixel.init('1764882963780389');
        ReactPixel.revokeConsent();

        ReactPixel.pageView(); // For tracking page view
        if(localStorage.getItem('PopupShown')){
            ReactPixel.grantConsent();
        }
    }, [popupShown])

    return (
        <>
            <Navbar showPowerspeed={showPowerspeed} />
            <Outlet />
            {!(popupShown || localStorage.getItem('PopupShown')) && <CookiePopup setPopupShown={setPopupShown} />}
            <Footer/>
        </>
    )
}

export default Wrapper
