import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  margin-bottoM: 2em;
  & img {
    max-width: 70%;
    height: auto;
    /* object-fit: contain; */
  }
  & img:first-of-type {
    position: relative;
    width: 280px;
  }
  & img:last-of-type {
    position: absolute;
    width: 235px;
    left: 11%;
    top: 8%;
  }

  @media screen and (max-width: 1360px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & div {
      margin-top: 2em;
      width: 100%;
      max-width: 100%;
    }

    & img:last-of-type {
      left: 45%;
      top: 5%;
    }

    & img:first-of-type {
      margin-right: 9em;
    }
  }

  @media screen and (max-width: 500px) {
    & img: last-of-type {
      max-width: 60%;
      top: 3%;
      left: 41%;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0 2rem 0;
  max-width: 900px;
  & h1 {
    margin: 0;
  }

  & ul {
    list-style: none;
    padding-left: 1em;
  }

  & li {
    margin: 0;
    padding: 0 0 0 30px;
    list-style: none;
    background-image: url(assets/aufzaehlungszeichen.png);
    background-repeat: no-repeat;
    background-position: 0px 4.5px;    
    background-size: 20px;
  }

  & div {
    max-width: 1200px;
  }
  & a {
    background-color: var(--red);
    color: white;
    border: 2px solid var(--red);
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    transition: 0.3s ease;
    @media screen and (max-width: 680px) {
			margin: 0 auto;
		}
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: white;
        color: var(--red);
      }
    }
  }
`;
