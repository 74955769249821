import React from 'react'
import { Landing, Content } from './Styles'

const Privacy = () => {
    return (
        <div style={{ minHeight: "140vh", backgroundColor: "#fff" }}>
            <Landing>
                <h1>Datenschutz</h1>
            </Landing>
            <Content>
                <h2>Datenschutz und Datensicherheit</h2>
                <p>Wir respektieren und sch&uuml;tzen ihre privaten Daten. Wenn Sie mit uns kommunizieren oder unsere Produkte oder Dienste benutzen, k&ouml;nnen pers&ouml;nliche Informationen gesammelt und gespeichert werden. Personenbezogene Informationen werden nicht an unbeteiligte Dritte weitergegeben, sondern ausschlie&szlig;lich f&uuml;r interne Zwecke und die Kommunikation mit Ihnen gespeichert.</p>
                <p>Die nachfolgende Erkl&auml;rung gibt Ihnen einen &Uuml;berblick dar&uuml;ber, wie wir Datenschutz gew&auml;hrleisten und welche Art von Daten zu welchem Zweck erhoben werden.</p>
                <p>&nbsp;</p>
                <h3>Gegenstand der Datenschutzerkl&auml;rung</h3>
                <p>Diese Erkl&auml;rung bezieht sich auf die Daten, die durch die Verwendung unserer App bekannt werden und informiert dar&uuml;ber, wie mit den Daten umgegangen wird.</p>
                <h3>Cookies</h3>
                <p>Es werden keine Cookies verwendet.</p>
                <h3>Datenschutz</h3>
                <p>Wie bereits erw&auml;hnt, werden die Daten nicht an Dritte weitergegeben. Die Eingaben sind danach und nach Beendigung der App nicht mehr verf&uuml;gbar oder rekonstruierbar.</p>
                <p>&Auml;nderungen dieser Datenschutzerkl&auml;rung</p>
                <p>RIS GmbH beh&auml;lt sich vor, diese Datenschutzerkl&auml;rung ohne Mitteilung zu &auml;ndern und per Update zu verteilen. Wenn Sie der Datenschutzerkl&auml;rung nicht zustimmen, deinstallieren Sie die App.</p>
                <h3>Analyse und soziale Netzwerke</h3>
                <p>Wir setzen Software zur Analyse der Benutzung von GEM2GO ein. Hierdurch k&ouml;nnen die Nutzung von GEM2GO ausgewertet werden sowie wertvolle Erkenntnisse &uuml;ber die Bed&uuml;rfnisse unserer User gewonnen werden. Diese Erkenntnisse tragen dazu bei, die Qualit&auml;t unseres Produkts noch weiter zu verbessern. (Matomo, Google Analytics for Firebase)</p>
                <p>&nbsp;</p>
                <p>Wir pflegen zudem noch Auftritte in Sozialen Netzwerken, im speziellen Facebook, zur Erh&ouml;hung des Bekanntheitsgrades unserer App und zu Marketingzwecken. Wenn Sie eine unserer oder GEM2GO besuchen bzw. nutzen, werden m&ouml;glicherweise personenbezogene Daten an den Betreiber des sozialen Netzwerkes &uuml;bermittelt. Dar&uuml;ber hinaus kann der Betreiber Ihr Profil mit unserem in Verbindung bringen, sofern Sie im jeweiligen Netzwerk eingeloggt sind.</p>
                <p><strong>Rechtsgrundlage</strong>: berechtigtes Interesse (Art 6 Abs 1 lit f DSGVO)</p>
                <p><strong>Empf&auml;nger/Empf&auml;ngerkategorien:</strong>&nbsp;Auftragsverarbeiter</p>
                <p>Details &uuml;ber die konkrete Datenerhebung und Verarbeitung durch den jeweiligen Betreiber finden Sie in den folgenden Links:</p>
                <p>Facebook:&nbsp;<a  href="https://de-de.facebook.com/about/privacy/"><strong>https://de-de.facebook.com/about/privacy/</strong></a></p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>Um die volle Funktionsf&auml;higkeit von GEM2GO anbieten zu k&ouml;nnen greift GEM2GO auf folgende Daten Ihres Ger&auml;tes zu:</p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>Standort</strong>: GEM2GO ermittelt auf Wunsch Ihren Standort, um Gemeinden in Ihrer Umgebung zu finden. Diese Daten werden nicht gespeichert und nicht weitergegeben.</p>
                <p><strong>Kalender</strong>: GEM2GO tr&auml;gt Veranstaltungen, Kalendertermine auf Wunsch in Ihren Kalender ein, liest und verwendet jedoch keine Daten aus Ihrem Kalender.</p>
                <p><strong>Kontakte</strong>: GEM2GO tr&auml;gt Kontakte auf Wunsch in Ihre Kontaktliste auf Ihrem Ger&auml;t ein, liest und verwendet jedoch keine Daten aus Ihren Kontakten.</p>
                <p><strong>Kamera</strong>: Mit GEM2GO k&ouml;nnen Sie mithilfe der Kamera Ihres Ger&auml;ts QR-Codes scannen und Bilder f&uuml;r die Anfragefunktion aufnehmen.</p>
                <p><strong>Speicher</strong>: GEM2GO verwendet auf Wunsch Bilder von Ihrem Smartphone f&uuml;r die Anfragefunktion.</p>
                <p>&nbsp;</p>
                <h3>Inhalte in GEM2GO</h3>
                <p>F&uuml;r die Inhalte von GEM2GO sind ausschlie&szlig;lich die Redakteure/Redakteurinnen der Gemeinden in &Ouml;sterreich sowie weiter unten angef&uuml;hrte externe Informationsanbieter verantwortlich. Die bereitgestellten Inhalte in der App wurden sorgf&auml;ltig gepr&uuml;ft und werden regelm&auml;&szlig;ig aktualisiert. Dennoch kann keine Garantie daf&uuml;r &uuml;bernommen werden, dass die in der App bereitgestellten Informationen vollst&auml;ndig, richtig und in jedem Fall aktuell sind.</p>
                <p>&nbsp;</p>
                <h3>Externe Informationsanbieter:</h3>
                <p><a  href="https://www.data.gv.at/katalog/dataset/2eb011e4-e899-4eee-885d-4b9da665033c">data.gv.at</a>&nbsp;- Beh&ouml;rdenverzeichnis<br /><a  href="https://www.karriere.at/">karriere.at</a>&nbsp;- Jobs<br /><a  href="https://www.nachrichten.at/">O&Ouml;Nachrichten</a>&nbsp;&ndash;&nbsp;<a  href="https://beste-stellen.nachrichten.at/">Jobs</a>&nbsp;und&nbsp;<a  href="https://immobilien.nachrichten.at/">Immobilien</a><br /><a  href="https://www.salzburg.com/">Salzburger Nachrichten</a>&nbsp;&ndash;&nbsp;<a  href="https://beste-stellen.salzburg.com/">Jobs</a>&nbsp;und&nbsp;<a  href="https://beste-immobilien.salzburg.com/">Immobilien</a><br /><a  href="https://www.findmyhome.at/">FindMyHome</a>- Immobilien<br /><a  href="https://www.help.gv.at/">Help.gv.at</a>&nbsp;- Lebenslagen<br /><a  href="http://www.hokify.at/">hokify</a>&nbsp;- Jobs<br /><a  href="http://www.it-kommunal.at/">Amtsweg.gv.at</a>&nbsp;- Online-Formulare<br /><a  href="https://www.pegelalarm.at/">Pegelalarm.at</a>&nbsp;- Pegelst&auml;nde<br /><a  href="https://www.fundamt.gv.at/">Fundamt.gv.at</a>&nbsp;- Fundgegenst&auml;nde<br /><br /></p>
                <p>Informationen zu weiteren Datenschutz-Hinweisen finden Sie hier:<a title="zu weiteren Datenschutz-Hinweisen" href="https://info.riskommunal.net/system/web/datenschutz.aspx?menuonr=223382083">&nbsp;Datenschutz-Hinweise</a></p>
                <p>&nbsp;</p>
                <p>Stand: Juni 2018</p>
            </Content>
        </div>
    )
}

export default Privacy