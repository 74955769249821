import React from 'react'

import { Wrapper, Header } from './Styles'

const Heading = ({id, top, header, isWhite=false, isBlue=false, isBluecode=false}) => {
    return (
        <Wrapper>
            <span><span id={id}></span>{top}</span>
            <Header white={isWhite} blue={isBlue} bluecode={isBluecode}>{header}</Header>
        </Wrapper>
    )
}

export default Heading
