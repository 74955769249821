import React, { useState } from 'react';
import RightNav from './RightNav';

import { StyledBurger } from './Styles'

const Burger = ({showPowerspeed}) => {
    const [open, setOpen] = useState(false)
    
    return (
      <>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>
        <RightNav open={open} setOpen={setOpen} showPowerspeed={showPowerspeed}/>
      </>
    )
  }
  export default Burger