import React from 'react'
import LazyLoad from 'react-lazyload'
import { isBrowser, isIOS, isAndroid, mobileVendor } from 'react-device-detect'

import { Wrapper, Content, Container, Links } from './Styles'

import { ReactComponent as Apple } from './appstore.svg';
import { ReactComponent as Android } from './playstore.svg';
import { ReactComponent as Samsung } from './003-samsung.svg';
import { ReactComponent as Huawei } from './012-huawei.svg';

const Download = ({ data }) => {
    return (
        <LazyLoad offset={300} height={320}>
            <Wrapper id="download" imgUrl={process.env.PUBLIC_URL + '/assets/Wallpaper.webp'}>
                <Content>
                    <Container>
                        <h2>Deine Gemeinde APP herunterladen</h2>
                        <span>{data}</span>
                        <Links>
                            {(isIOS || isBrowser) &&
                                <a href="https://apps.apple.com/at/app/gem2go-die-gemeinde-app/id547223237" rel="noopener noreferrer" target="_blank">
                                    {/* <FontAwesomeIcon icon={faApple} fixedWidth /> */}
                                    <Apple />
                                    {/* <img src={apple} alt="App Store Icon" /> */}
                                    <span>App Store</span>
                                </a>
                            }
                            {(isAndroid || isBrowser) &&
                                <a href="https://play.google.com/store/apps/details?id=WA66F5VEHP.com.ris.Gem2Go" rel="noopener noreferrer" target="_blank">
                                    {/* <FontAwesomeIcon icon={faAndroid} fixedWidth /> */}
                                    <Android />
                                    <span>Google Play</span>
                                </a>
                            }
                            {(mobileVendor === "Samsung" || isBrowser) &&
                                <a href="https://galaxy.store/gem2" rel="noopener noreferrer" target="_blank">
                                    {/* <FontAwesomeIcon icon={faStore} fixedWidth /> */}
                                    <Samsung/>
                                    <span>Galaxy Apps</span>
                                </a>
                            }
                            {(mobileVendor === "Huawei" || isBrowser) &&
                                <a href="https://appgallery.huawei.com/#/app/C101964617" rel="noopener noreferrer" target="_blank">
                                    {/* <FontAwesomeIcon icon={faStore} fixedWidth /> */}
                                    <Huawei />
                                    <span>Huawei Store</span>
                                </a>
                            }
                        </Links>
                    </Container>
                    <img src={process.env.PUBLIC_URL + '/assets/iphone2-alt.webp'} alt="Hand mit einem IPhone welches das GEM2GO Logo anzeigt" />
                </Content>
            </Wrapper>
        </LazyLoad>
    )
}

export default Download