import React from 'react'
import { HashLink } from 'react-router-hash-link';
import DOMPurify from 'dompurify';
import LazyLoad from 'react-lazyload'
import Heading from '../heading'
import { Wrapper, Container } from './Styles'

const Introduction = ({ text }) => {
    return (
        <div id="app">
            <Heading top="Die App" header="Das ist die GEM2GO APP!" />
            <LazyLoad  offset={300}>
                <Container>
                    <img src={process.env.PUBLIC_URL + 'assets/gem2goMuellkalender.png'} alt="Startseite der Gemeinde Sierning in der GEM2GO App mit einem roten dahinterliegenden Smartphone welches das GEM2GO Logo auf dem Bildschirm darstellt" />
                    <img src={process.env.PUBLIC_URL + 'assets/gem2goStartseite.png'} alt="Startseite der Gemeinde Sierning in der GEM2GO App mit einem roten dahinterliegenden Smartphone welches das GEM2GO Logo auf dem Bildschirm darstellt" />
                    <Wrapper>
                        <div className="text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}></div>
                        <HashLink smooth to="/#features">zu den Funktionen</HashLink>
                    </Wrapper>
                </Container>
            </LazyLoad>
        </div>
    )
}

export default Introduction
