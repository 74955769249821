import styled, { css } from "styled-components";

export const Wrapper = styled.div`
	position: fixed;
	max-width: 1400px;
	width: 90vw;
	left: 50%;
	bottom: 1rem;
	transform: translate(-50%);
	padding: 1rem;
	border-radius: 5px;
	box-shadow: var(--box-shadow);
	background-color: white;
	z-index: 1000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	flex-wrap: wrap;
	& span {
		margin-left: 0.5rem;
	}
	@media screen and (max-width: 680px) {
		justify-content: center;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
`;

const buttonStyles = css`
	margin: 0.5rem;
	padding: 0.5rem 1rem;
	border-radius: 10px;
	transition: 0.3s ease;
	&:hover {
		cursor: pointer;
	}
`;

export const DeclineButton = styled.button`
	${buttonStyles};
	color: var(--red);
	border: 1px solid var(--red);
`;

export const AcceptButton = styled.button`
	${buttonStyles};
	background: var(--red);
	color: white;
`;
